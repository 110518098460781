import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Stack, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { NavLink as RouterLink } from 'react-router-dom';
import Slider from 'react-slick';
import useResponsive from '../hooks/useResponsive';
import Logo from '../components/logo';
import axios from '../utils/Axios';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FormProvider, { RHFTextField } from '../components/hook-form';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(() => ({
  width: '50%',
  padding: 50,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: localStorage.getItem("primaryColor") ?? '#46C492',
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const urlSearchParams = new URLSearchParams(window.location.search);
const token = urlSearchParams.get('token');

export default function ResetPage() {
  const settings = {
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const methods = useForm({
    defaultValues: { password: '', confirm_password: '' },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorForm, setErrorForm] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const mdUp = useResponsive('up', 'sm');

  const onSubmit = async (data) => {
    if (!token) return;
    try {
      const response = await axios.post('/auth/reset', { token, ...data });
      if (response.status === 200) {
        setSuccessMessage(true);
      }
    } catch (error) {
      reset();
      setErrorForm({ message: error.messages.join('\n') });
    }
  };

  return (
    <>
      <Helmet>
        <title> Reset Password | {localStorage.getItem("companyName") ?? "FlowBookings"} </title>
      </Helmet>

      <StyledRoot>
        <Logo
          width="160px"
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        <Container>
          <StyledContent>
            <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
              Reset Password
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', textAlign: 'center', mb: 2 }}
            >
              Please choose your new password.
            </Typography>
            {!token && (
              <Typography
                sx={{
                  color: 'text.primary',
                  textAlign: 'center',
                  fontSize: '1.6rem',
                }}
              >
                Please provide a valid token
              </Typography>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Password Changed !
              </Alert>
            )}
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              {!!errorForm && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errorForm.message}
                </Alert>
              )}
              {!successMessage && token && (
                <>
                  <RHFTextField
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    size="large"
                    placeholder="Enter new password"
                    InputProps={{
                      sx: { mb: 3 },
                    }}
                  />
                  <RHFTextField
                    name="confirm_password"
                    type="password"
                    size="large"
                    placeholder="Confirm new password"
                    InputProps={{
                      sx: { mb: 3 },
                    }}
                  />
                  <LoadingButton
                    sx={{ mt: 2, backgroundColor: '#36B37E' }}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Reset Password
                  </LoadingButton>
                </>
              )}
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ justifyContent: 'center', mt: 2 }}
              >
                <Typography
                  component={RouterLink}
                  to="/login"
                  variant="body2"
                  sx={{ color: localStorage.getItem("primaryColor") ?? '#46C492', fontWeight: 'bold' }}
                >
                  Back To Log in
                </Typography>
              </Stack>
            </FormProvider>
          </StyledContent>
        </Container>

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ mb: 3, color: '#fff' }}>
              The Ultimate Booking Solution <br /> for Webflow!
            </Typography>

            <Slider {...settings}>
              <Box
                component="img"
                src="/assets/images/products/Cloneable_7.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_10.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_12.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
              <Box
                component="img"
                src="/assets/images/products/Cloneable_14.png"
                sx={{
                  objectFit: 'cover',
                  borderRadius: 1.5,
                }}
              />
            </Slider>
          </StyledSection>
        )}
      </StyledRoot>
    </>
  );
}
