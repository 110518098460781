import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert, Grid, Box, Card, CardContent, Avatar, Button, TextField } from '@mui/material';
import Profile from '../sections/@dashboard/account/Profile';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';
import { TwitterPicker, ChromePicker } from 'react-color';
import { useTheme } from '@mui/material/styles';
import { themeEventEmitter } from 'src/theme';

export default function AgencyBranding() {
  const { user, setGlobalUser } = useAuthContext();
  const theme = useTheme();

  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [loadCount, setLoadCount] = useState(0);
  const [primaryColor, setPrimaryColor] = useState(user.theme.primaryColor);
  const [accentColor, setAccentColor] = useState(user.theme.accentColor);
  const [logo, setLogo] = useState(user.theme.logo);
  const [domain, setDomain] = useState(user.connectedDomain);

  const updateTheme = async (primaryColor, accentColor, logo) => {
    try {

      theme.palette.primary.main = user.theme.primaryColor;
      theme.palette.secondary.main = user.theme.accentColor;

      const response = await axios.post(`/agency/update-theme`, {
        primaryColor: primaryColor?.toLowerCase(),
        accentColor: accentColor?.toLowerCase(),
        logo: logo,
      });
      setSuccess({ bg: 'success', message: response.data.message });

      if (primaryColor) {
        localStorage.setItem('primaryColor', primaryColor?.toLowerCase());
      }
      if (accentColor) {
        localStorage.setItem('accentColor', accentColor?.toLowerCase());
      }
      if (logo) {
        localStorage.setItem('logo', logo);
      }

      themeEventEmitter.emit('updateTheme');

      setTimeout(() => setSuccess(null), 6000);
    } catch (error) {
      setFormErrors({ message: error.message });
      setTimeout(() => setFormErrors(null), 6000);
    }
  };

  const connectDomain = async (domain) => {
    if (!domain) {
      setFormErrors({ message: 'Please provide a domain name' });
      setTimeout(() => setFormErrors(null), 6000);
      return;
    };

    const response = await axios.post(`/agency/connect-domain`, {
      domain: domain,
    });

    setSuccess({ bg: 'success', message: response.data.message });
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Agency Branding Setup | FlowBookings</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Agency Branding Setup
        </Typography>
        {!!formErrors && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {formErrors.message}
          </Alert>
        )}
        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}

        <Grid container spacing={3} sx={{ mb: 5 }}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                fontFamily: 'Public Sans'
              }}
            >
              <TextField
                style={{ marginRight: '10px' }}
                id="primaryColor"
                value={primaryColor}
                label="Primary Color"
                onChange={(e) => {
                  theme.palette.primary.main = e.target.value;
                  setPrimaryColor(e.target.value);
                  setAccentColor(e.target.value);
                  setTimeout(() => {
                    setLoadCount(loadCount + 1);
                  }, 200);
                }}
              />
            </Box>

            <Box
              sx={{
                mt: 2,
                mb: 2,
                fontFamily: 'Public Sans'
              }}
            >
              <Button
                variant="contained"
                onClick={() => updateTheme(primaryColor, accentColor, logo)}
              >
                Update Colors
              </Button>
            </Box>

          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Agency Logo
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Please provide your agency logo for it to be displayed on both your dashboard and your customers' dashboards.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                >

                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    mt: 2,
                  }}
                >
                  <Button variant="contained" component="label">
                    Change Logo
                    <input type="file" hidden onChange={async (e) => {

                      const response = await axios.post('/upload');
                      const signData = response.data;

                      const formData = new FormData();
                      formData.append('file', e.target.files[0]);

                      const url = `https://api.cloudinary.com/v1_1/de7fg4j42/image/upload?api_key=437499416445219&timestamp=${signData.timestamp}&signature=${signData.signature}&discard_original_filename=true&folder=${signData.folder}`;

                      const imageResponse = await fetch(url, {
                        method: 'POST',
                        body: formData,
                      });

                      const imageJson = await imageResponse.json();

                      setLogo(imageJson.url);
                      updateTheme(primaryColor, accentColor, imageJson.url)
                    }} />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Connect Domain
                </Typography>
                {!user.connectedDomain ? <Typography variant="body2" gutterBottom>
                  To connect your domain please do the following:-
                  <ul style={{ marginLeft: '30px', marginTop: '15px', marginBottom: '30px' }}>
                    <li>1. Create "A" record for your domain or sub domain with the value of <b>3.130.233.17</b></li>
                    <li>2. Once you have created the "A" record, please enter your domain or sub domain below and click "Connect Domain"</li>
                    <li>3. Once you have connected your domain, please wait for 10-20 minutes for the domain to propagate</li>
                    <li>4. Come back to this page and if you see your domain below, then you have successfully connected your domain</li>
                    <li>5. If not, then recheck your "A" record and again enter your domain or sub domain below and click "Connect Domain" and wait for 10-20 minutes</li>
                  </ul>
                </Typography> : <Typography variant="body2" gutterBottom></Typography>}
                <Box
                  sx={{
                    display: 'flex',
                    mt: 2,
                  }}
                >
                  <TextField
                    value={domain}
                    label="Domain"
                    onChange={(e) => {
                      setDomain(e.target.value);
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    fontFamily: 'Public Sans'
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => { connectDomain(domain) }}
                    disabled={!!user.connectedDomain}
                  >
                    {!!user.connectedDomain ? 'Domain Connected' : 'Connect Domain'}
                  </Button>
                </Box>

              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container>
    </>
  );
}
