import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import OnboardingLayout from './layouts/onboarding';

// pages
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import DashboardAgencyPage from './pages/DashboardAgencyPage';
import ServicesPage from './pages/ServicesPage';
import BookingsPage from './pages/BookingsPage';
import ProductsPage from './pages/ProductsPage';
import CustomersPage from './pages/CustomersPage';
import GlobalSettingsPage from './pages/GlobalSettingsPage';
import EmailCustomizerPage from './pages/EmailCustomizerPage';
import VerifyLoginPage from './pages/VerifyLoginPage';
import ProfilePage from './pages/ProfilePage';
import OnboardingPage from './pages/OnboardingPage';
import RegisterPage from './pages/RegisterPage';
import RequestResetPage from './pages/RequestResetPage';
import ResetPage from './pages/ResetPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import AgencyBranding from './pages/AgencyBranding';
import AgencyEmail from './pages/AgencyEmail';
import AgencyStripe from './pages/AgencyStripe';


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '/dashboard/agency', element: <DashboardAgencyPage /> },
        { path: '/dashboard/app', element: <DashboardAppPage /> },
        { path: '/dashboard/bookings', element: <BookingsPage /> },
        { path: '/dashboard/forms', element: <ProductsPage /> },
        { path: '/dashboard/services', element: <ServicesPage /> },
        { path: '/dashboard/customers', element: <CustomersPage /> },
        { path: '/dashboard/settings', element: <GlobalSettingsPage /> },
        { path: '/dashboard/emails', element: <EmailCustomizerPage /> },
        { path: '/dashboard/profile', element: <ProfilePage /> },
        { path: '/dashboard/agency/branding', element: <AgencyBranding /> },
        { path: '/dashboard/agency/email', element: <AgencyEmail /> },
        { path: '/dashboard/agency/stripe', element: <AgencyStripe /> },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/verify-email',
      element: <VerifyEmailPage />,
    },
    {
      path: '/register',
      element: <RegisterPage />,
    },
    {
      path: '/reset-request',
      element: <RequestResetPage />,
    },
    {
      path: '/reset-pass',
      element: <ResetPage />,
    },
    {
      element: <OnboardingLayout />,
      children: [
        {
          path: '/onboarding',
          element: <OnboardingPage />,
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: 'verify', element: <VerifyLoginPage /> },
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
