import SvgColor from '../../../components/svg-color';

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const agencyNavConfig = [
  {
    title: 'Agency Dashboard',
    path: '/dashboard/agency',
    icon: icon('ic_dashboard'),
    isSubOnly: false,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Connect Stipe',
    path: '/dashboard/agency/stripe',
    icon: icon('ic_stripe'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Connect Email',
    path: '/dashboard/agency/email',
    icon: icon('ic_email'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Branding',
    path: '/dashboard/agency/branding',
    icon: icon('ic_edit'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Account',
    icon: icon('ic_user'),
    isSubOnly: false,
    isExternalLink: false,
    stripePortal: {
      title: 'Billing',
      path: null,
      icon: icon('ic_pop'),
    },
    children: [
      { title: 'Profile', path: '/dashboard/profile', icon: icon('ic_dot') },
    ],
  },
  // {
  //   title: 'Support',
  //   icon: icon('ic_pop'),
  //   isSubOnly: false,
  //   isExternalLink: true,
  //   path: 'https://www.flowbookings.io/contact',
  //   children: [],
  // },
];

export default agencyNavConfig;
