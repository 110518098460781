import SvgColor from '../../../components/svg-color';

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_dashboard'),
    isSubOnly: false,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Form Builder',
    path: '/dashboard/forms',
    icon: icon('ic_edit'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Services',
    path: '/dashboard/services',
    icon: icon('ic_list'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Bookings',
    path: '/dashboard/bookings',
    icon: icon('ic_calendar'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Customers',
    path: '/dashboard/customers',
    icon: icon('ic_user_group'),
    isSubOnly: true,
    isExternalLink: false,
    children: [],
  },
  {
    title: 'Settings',
    icon: icon('ic_setting'),
    isSubOnly: true,
    isExternalLink: false,
    children: [
      {
        title: 'Global Settings',
        path: '/dashboard/settings',
        icon: icon('ic_dot'),
      },
      {
        title: 'Email Customizer',
        path: '/dashboard/emails',
        icon: icon('ic_dot'),
      },
    ],
  },
  {
    title: 'Account',
    icon: icon('ic_user'),
    isSubOnly: false,
    isExternalLink: false,
    stripePortal: {
      title: 'Billing',
      path: null,
      icon: icon('ic_pop'),
    },
    children: [
      { title: 'Profile', path: '/dashboard/profile', icon: icon('ic_dot') },
    ],
  },
  {
    title: 'Support',
    icon: icon('ic_pop'),
    isSubOnly: false,
    isExternalLink: true,
    path: 'https://www.flowbookings.io/contact',
    children: [],
  },
];

export default navConfig;
