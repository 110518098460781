import { findIndex } from 'lodash';
import { useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  Box,
  Popover,
  FormControl,
  Select,
} from '@mui/material';
import Iconify from '../iconify';
import { getTimeOptions, formatBreakTime } from '../../utils/formatTime';
import { WorkingHoursSkeleton } from '../skeleton';
import palette from 'src/theme/palette';

const DAYS_IN_WEEK = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export default function WorkingHours({ availability, setAvailability }) {
  const [popover, setPopover] = useState('');
  const [anchorBreak, setAnchorBreak] = useState(null);

  const [dirtyBreak, setDirtyBreak] = useState({});

  const options = getTimeOptions();
  const timeOptionsSelect = options.map((option, index) => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  ));

  function handleShowPopover(event, weekDay) {
    setPopover(weekDay);
    setAnchorBreak(event.currentTarget);
  }

  function handleClosePopover() {
    setPopover('');
    setAnchorBreak(null);
  }

  const handleChangeDateOnDay = (event, day) => {
    const availabilityOnDayIndex = findIndex(availability, { day });
    availability[availabilityOnDayIndex][event.target.name] =
      event.target.value;
    setAvailability([...availability]);
  };

  const handleChangeBreak = (event, day) => {
    const mutatedBreaks = { ...dirtyBreak };
    const isValidDate = event.target.value !== 'off';

    if (!isValidDate) return;

    if (mutatedBreaks[day]) {
      mutatedBreaks[day] = {
        ...mutatedBreaks[day],
        [event.target.name]: event.target.value,
      };
    } else {
      mutatedBreaks[day] = {
        [event.target.name]: event.target.value,
      };
    }
    setDirtyBreak(mutatedBreaks);
  };

  const handleAddBreak = (event, day) => {
    if (!availability.length) return;

    const newBreak = dirtyBreak[day];
    if (!newBreak) return;

    const mutatedAvailability = [...availability];

    const availabilityOnDayIndex = findIndex(mutatedAvailability, {
      day: Number(day),
    });
    if (availabilityOnDayIndex !== -1) {
      mutatedAvailability[availabilityOnDayIndex].breaks.push(newBreak);
    }

    setAvailability(mutatedAvailability);
    setDirtyBreak({});

    handleClosePopover(event, '0');
  };

  const handleRemoveBreak = ({ day, breakIndex }) => {
    const mutatedAvailability = [...availability];
    const availabilityOnDayIndex = findIndex(mutatedAvailability, {
      day: Number(day),
    });

    if (availabilityOnDayIndex === -1) return;

    mutatedAvailability[availabilityOnDayIndex].breaks.splice(breakIndex, 1);
    setAvailability(mutatedAvailability);
  };

  const handleCancelAddBreak = (event, day) => {
    delete dirtyBreak[day];
    handleClosePopover(event, '0');
  };

  return (
    <Stack>
      {!availability.length &&
        [...Array(7)].map(() => <WorkingHoursSkeleton />)}
      {availability.map(({ day, startDate, endDate, breaks }) => (
        <FormControl fullWidth>
          <Typography>{DAYS_IN_WEEK[day]}</Typography>
          <Box
            rowGap={1}
            columnGap={1}
            display="grid"
            sx={{ mt: 2, mb: 2 }}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr) 102px',
            }}
          >
            <Select
              native
              defaultValue={startDate}
              size="small"
              onChange={(event) => handleChangeDateOnDay(event, day)}
              name="startDate"
            >
              <option value="off">
                <em>Off</em>
              </option>
              {timeOptionsSelect}
            </Select>

            <Select
              native
              defaultValue={endDate}
              size="small"
              onChange={(event) => handleChangeDateOnDay(event, day)}
              name="endDate"
            >
              <option value="off">
                <em>Off</em>
              </option>
              {timeOptionsSelect}
            </Select>
            <Button
              aria-describedby="0"
              sx={{
                fontSize: '14px',
                backgroundColor: palette.primary.lighter,
                color: palette.primary.main,
              }}
              onClick={(event) => handleShowPopover(event, day)}
            >
              Add Break
            </Button>
            <Popover
              anchorEl={anchorBreak}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={(event) => handleClosePopover(event, day)}
              open={Boolean(popover === day)}
            >
              <FormControl fullWidth>
                <Typography sx={{ p: 2 }}>Add Break</Typography>
                <Box
                  rowGap={3}
                  columnGap={1}
                  display="grid"
                  sx={{ padding: '2px 11px 11px 11px' }}
                  gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                  }}
                >
                  <Select
                    native
                    defaultValue={options[0]}
                    size="small"
                    name="startDate"
                    onChange={(event) => handleChangeBreak(event, day)}
                  >
                    <option value="off">
                      <em>Off</em>
                    </option>
                    {timeOptionsSelect}
                  </Select>

                  <Select
                    native
                    defaultValue={options[0]}
                    size="small"
                    name="endDate"
                    onChange={(event) => handleChangeBreak(event, day)}
                  >
                    <option value="off">
                      <em>Off</em>
                    </option>
                    {timeOptionsSelect}
                  </Select>
                  <Button
                    variant="outlined"
                    onClick={(event) => handleCancelAddBreak(event, day)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={(event) => handleAddBreak(event, day)}
                  >
                    Save
                  </Button>
                </Box>
              </FormControl>
            </Popover>
          </Box>
          <div>
            {breaks.length
              ? breaks.map((breakDay, index) => (
                <Button
                  variant="outlined"
                  sx={{ color: '#000000', mb: 2, mr: 2 }}
                  onClick={(event) =>
                    handleRemoveBreak({ day, breakIndex: index })
                  }
                >
                  <Iconify
                    icon="eva:clock-outline"
                    sx={{ float: 'left', mr: 1 }}
                  />
                  {formatBreakTime(breakDay.startDate)}
                  {' - '}
                  {formatBreakTime(breakDay.endDate)}
                  <Iconify icon="eva:close-fill" sx={{ float: 'right' }} />
                </Button>
              ))
              : ''}
          </div>
        </FormControl>
      ))}
    </Stack>
  );
}
