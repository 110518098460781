import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert, Card, CardContent, Button, Input, Box } from '@mui/material';
import Profile from '../sections/@dashboard/account/Profile';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';

export default function AgencyEmail() {
  const { user, setGlobalUser } = useAuthContext();
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const [email, setEmail] = useState(user.sesEmail ? user.sesEmail : localStorage.getItem("email") ? localStorage.getItem("email") : '');
  const [emailSent, setEmailSent] = useState(false);

  const sendLink = async (email) => {
    try {
      const response = await axios.post(`/agency/connect-email`, { email });
      setSuccess({ bg: 'success', message: response.data.message });
      setEmailSent(true);
      // setTimeout(() => setSuccess(null), 6000);
    } catch (error) {
      setFormErrors({ message: error.message });
      setTimeout(() => setFormErrors(null), 6000);
    }
  };

  const verifyEmail = async (email) => {
    try {
      setSuccess(null);
      const response = await axios.post(`/agency/verify-email`, { email });
      setSuccess({ bg: 'success', message: response.data.message });
      setEmailSent(true);
      setTimeout(() => setSuccess(null), 6000);
    } catch (error) {
      setFormErrors({ message: error.message });
      setTimeout(() => setFormErrors(null), 6000);
    }
  };

  return (
    <>
      <Helmet>
        <title>Agency Email Setup | FlowBookings</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 1 }}>
          Agency Email Setup
        </Typography>

        <Typography variant="body1" sx={{ mb: 5 }}>
          Enter your agency’s email address that should be used to send notifications to your clients from from.
        </Typography>

        {!!formErrors && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {formErrors.message}
          </Alert>
        )}
        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}

        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Send Verification Link
            </Typography>
            <Typography variant="body2" gutterBottom>
              You’ll receive a confirmation link on it to verify the email address. The link expires in 24 hours. Once verified please click on the Connect Email button below.
            </Typography>

            <Input
              fullWidth
              label="Email Address"
              margin="normal"
              name="email"
              type="email"
              value={email}
              variant="outlined"
              onChange={(event) => setEmail(event.target.value)}
            />

            {!user.sesEmail ? <Box sx={{ mt: 3 }}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: '10px' }}
                onClick={() => sendLink(email)}
              >
                Send Verification Link
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => verifyEmail(email)}
              >
                Connect Email
              </Button>
            </Box> : <Box sx={{ mt: 3 }}>
              <Button
                color="primary"
                variant="contained"
                disabled
              >
                Email Connected
              </Button>
            </Box>}
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
