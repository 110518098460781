import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import {
  Paper,
  Stack,
  Table,
  MenuItem,
  Popover,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Grid,
  TablePagination,
  Card,
  CardContent,
  Box,
  Button,
  Alert
} from '@mui/material';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Label from '../components/label';
import { TableListHead } from '../sections/@dashboard/bookings';
import Scrollbar from '../components/scrollbar';
import { BOOKING_STATUS_COLORS } from '../theme/palette';
import Iconify from '../components/iconify';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  formatBookingDate,
  formatBookingTime,
  isTodayDate,
  isExpiredSubscription,
} from '../utils/formatTime';
import { useAuthContext } from '../auth/useAuthContext';
import PricingPlans from '../sections/@dashboard/app/PricingPlans';
import { Link } from 'react-router-dom';

const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'subscription.plan', label: 'Plan Name', alignRight: false },
  { id: 'subscription.plan_status', label: 'Status', alignRight: false },
];

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function DashboardAgencyPage() {
  const { user, setGlobalUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [clients, setClients] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);

  const [selectedClientRow, setSelectedClientRow] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedClientRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (selectedClientRow != null) {
      setSelectedClientRow(null);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startDate');

  const {
    user: { _id, config, subscription },
  } = useAuthContext();

  const isExpired =
    subscription && isExpiredSubscription(subscription.dateExpiry);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedClientRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getCients = useCallback(async () => {
    try {
      const clientsResponse = await axios.get('/agency/clients');
      setClients(clientsResponse.data.clients);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCients();
  }, []);

  const activeClients = clients.filter(
    ({ status }) => status === 'PENDING' || status === 'APPROVED'
  );

  const filteredClients = applySortFilter(
    activeClients,
    getComparator(order, orderBy)
  );

  const todayClients = activeClients.filter(({ startDate }) =>
    isTodayDate(startDate, config.timezone)
  );

  // const checkAgencyStatus = (user.stripeAccountStatus != "ONBOARDED" || !user.sesEmail || !user.theme?.primaryColor);
  const checkAgencyStatus = (user.stripeAccountStatus != "ONBOARDED" || !user.sesEmail || !user.theme?.primaryColor);

  return (
    <>
      <Helmet>
        <title> Dashboard | FlowBookings </title>
      </Helmet>
      <Container maxWidth="xl">
        {isCopied && (
          <Alert severity="success" color="info" sx={{ mb: 3 }}>
            Copied to clipboard
          </Alert>
        )}

        {(checkAgencyStatus) && <Grid container spacing={3} sx={{ mb: 5 }}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Connect Stripe
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Connect your Stripe account to add clients to your dashboard.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  {user.stripeAccountStatus != "ONBOARDED" ? <Link to="/dashboard/agency/stripe">
                    <Button
                      color="primary"
                      variant="contained"
                    >
                      Connect Stripe
                    </Button>
                  </Link> : <Button
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    Stripe Connected
                  </Button>}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Link Email
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Link your email to send email notifications to your clients’ customers.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  {!user.sesEmail ? <Link to="/dashboard/agency/email">
                    <Button
                      color="primary"
                      variant="contained"
                    >
                      Link Email
                    </Button>
                  </Link> : <Button
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    Email Linked
                  </Button>}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Customize Branding
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Customize your branding to match your business.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Link to="/dashboard/agency/branding">
                    <Button
                      color="primary"
                      variant="contained"
                    >
                      Customize Branding
                    </Button>
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>}

        {(!checkAgencyStatus) && <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Clients
          </Typography>

          <Button color="primary" variant="contained" onClick={() => {
            let domain = user.connectedDomain ? user.connectedDomain : user.subDomain;
            const url = "https://" + domain + "/register";
            navigator.clipboard.writeText(url);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 4000);
            e.preventDefault();
          }}>
            Copy Link
          </Button>
        </Stack>}

        {(!checkAgencyStatus) && <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 100 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={0}
                  onRequestSort={handleRequestSort}
                />
                {isLoading && [...Array(5)].map(() => <TableSkeleton />)}
                {!isLoading && clients.length ? (
                  <TableBody>
                    {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow hover key={_id} tabIndex={-1}>
                            <TableCell component="th" scope="row">
                              <Stack
                                direction="column"
                                alignItems="left"
                                spacing={0.5}
                              >
                                <Typography variant="subtitle2" noWrap>
                                  {row.firstName}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap>
                                {row.lastName}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle3">
                                {row.email}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle3">
                                {row.subscription.plan}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle3">
                                {row.subscription.plan_status}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  ''
                )}
                {!clients.length && !isLoading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No Clients Found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={activeClients.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>}

      </Container>
    </>
  );
}
