import { Helmet } from 'react-helmet-async';
import { useState, useCallback, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import {
  Paper,
  Stack,
  Table,
  MenuItem,
  Popover,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  Grid,
  TablePagination,
  Card,
} from '@mui/material';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Label from '../components/label';
import { TableListHead } from '../sections/@dashboard/bookings';
import Scrollbar from '../components/scrollbar';
import { BOOKING_STATUS_COLORS } from '../theme/palette';
import Iconify from '../components/iconify';
import axios from '../utils/Axios';
import { TableSkeleton } from '../components/skeleton';
import {
  formatBookingDate,
  formatBookingTime,
  isTodayDate,
  isExpiredSubscription,
} from '../utils/formatTime';
import { useAuthContext } from '../auth/useAuthContext';
import BookingModal from '../sections/@dashboard/bookings/Modal';
import PricingPlans from '../sections/@dashboard/app/PricingPlans';

const TABLE_HEAD = [
  { id: 'customer.firstName', label: 'Customer', alignRight: false },
  { id: 'startDate', label: 'Date', alignRight: false },
  { id: 'service.name', label: 'Service', alignRight: false },
  { id: 'startDate', label: 'Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', alignRight: false },
];

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (orderBy.includes('.')) {
    const splitMe = orderBy.split('.');
    const first = splitMe[0];
    const second = splitMe[1];

    if (b[first][second] < a[first][second]) {
      return -1;
    }
    if (b[first][second] > a[first][second]) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function DashboardAppPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [openPopover, setOpenPopover] = useState(null);

  const [agencyLoaded, setAgencyLoaded] = useState(false);

  const [selectedBookingRow, setSelectedBookingRow] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (row) => {
    setOpenPopover(null);
    setOpenModal(true);
    setSelectedBookingRow(row);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (selectedBookingRow != null) {
      setSelectedBookingRow(null);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startDate');

  const {
    user: { _id, config, subscription },
  } = useAuthContext();

  const isExpired =
    subscription && isExpiredSubscription(subscription.dateExpiry);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenMenu = (event, rowId) => {
    setOpenPopover(event.currentTarget);
    setSelectedBookingRow({ _id: rowId });
  };

  const handleCloseMenu = () => {
    setOpenPopover(null);
  };

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getBookings = useCallback(async () => {
    try {
      const [bookingsResponse, servicesResponse, customersResponse] =
        await Promise.all([
          axios.get('/bookings'),
          axios.get('/services'),
          axios.get('/customers'),
        ]);
      setBookings(bookingsResponse.data.bookings);
      setServices(servicesResponse.data.services);
      setCustomers(customersResponse.data.customers);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!isExpired) {
      getBookings();
    }
  }, []);

  useEffect(() => {
    if (window.location.hostname == 'agency.flowbookings.io') {
      window.location.href = '/dashboard/agency';
    }
    setAgencyLoaded(true);
  }, []);

  const activeBookings = bookings.filter(
    ({ status }) => status === 'PENDING' || status === 'APPROVED'
  );

  const filteredBookings = applySortFilter(
    activeBookings,
    getComparator(order, orderBy)
  );

  const todayBookings = activeBookings.filter(({ startDate }) =>
    isTodayDate(startDate, config.timezone)
  );

  return (
    <>
      <Helmet>
        <title> Dashboard | {localStorage.getItem("companyName") ?? "FlowBookings"} </title>
      </Helmet>
      {agencyLoaded && <Container maxWidth="xl">
        {!subscription.subscriptionId && <PricingPlans user={_id} />}
        {!isExpired ? (
          <>
            <Grid container spacing={3} sx={{ mb: 5 }}>
              <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Bookings Today"
                  total={todayBookings.length || 0}
                  color="warning.main"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Bookings"
                  total={bookings.length || 0}
                  color="success.main"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AppWidgetSummary
                  title="Total Customers"
                  total={customers.length || 0}
                  color="#3366FF"
                />
              </Grid>
            </Grid>

            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 100 }}>
                  <Table>
                    <TableListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={0}
                      onRequestSort={handleRequestSort}
                    />
                    {isLoading && [...Array(5)].map(() => <TableSkeleton />)}
                    {!isLoading && filteredBookings.length ? (
                      <TableBody>
                        {filteredBookings
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            const {
                              _id,
                              customer,
                              startDate,
                              status,
                              service,
                            } = row;
                            return (
                              <TableRow hover key={_id} tabIndex={-1}>
                                <TableCell component="th" scope="row">
                                  <Stack
                                    direction="column"
                                    alignItems="left"
                                    spacing={0.5}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {customer.firstName} {customer.lastName}
                                    </Typography>
                                    <Typography variant="subtitle3">
                                      {customer.email}
                                    </Typography>
                                  </Stack>
                                </TableCell>

                                <TableCell align="left">
                                  {formatBookingDate(
                                    startDate,
                                    config.timezone
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  {service.name}
                                </TableCell>
                                <TableCell align="left">
                                  {formatBookingTime(
                                    startDate,
                                    config.timezone
                                  )}
                                </TableCell>

                                <TableCell align="left">
                                  <Label
                                    sx={{
                                      color:
                                        BOOKING_STATUS_COLORS[status].color,
                                      background:
                                        BOOKING_STATUS_COLORS[status]
                                          .background,
                                      textAlign: 'center',
                                    }}
                                  >
                                    {sentenceCase(status)}
                                  </Label>
                                </TableCell>

                                <TableCell align="right">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(event) =>
                                      handleOpenMenu(event, _id)
                                    }
                                  >
                                    <Iconify icon="eva:more-vertical-fill" />
                                  </IconButton>
                                </TableCell>
                                <Popover
                                  open={Boolean(
                                    openPopover &&
                                    selectedBookingRow &&
                                    selectedBookingRow._id === _id
                                  )}
                                  anchorEl={openPopover}
                                  onClose={handleCloseMenu}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  PaperProps={{
                                    sx: {
                                      p: 1,
                                      width: 140,
                                      '& .MuiMenuItem-root': {
                                        px: 1,
                                        typography: 'body2',
                                        borderRadius: 0.75,
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => handleOpenModal(row)}
                                  >
                                    <Iconify
                                      icon="eva:eye-outline"
                                      sx={{ mr: 2 }}
                                    />
                                    View
                                  </MenuItem>
                                </Popover>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    ) : (
                      ''
                    )}
                    {!filteredBookings.length && !isLoading && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                No Upcoming Bookings
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={activeBookings.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : null}
      </Container>}

      <BookingModal
        booking={selectedBookingRow}
        services={services}
        customers={customers}
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />
    </>
  );
}
