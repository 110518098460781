import { useCallback, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert } from '@mui/material';
import EmailForm from '../sections/@dashboard/settings/EmailForm';
import axios from '../utils/Axios';

export default function GlobalSettingsPage() {
  const [emails, setEmails] = useState({});
  const [success, setSuccess] = useState(null);

  const getEmails = useCallback(async () => {
    try {
      const emailsResponse = await axios.get('/emails');
      setEmails(emailsResponse.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const updateEmails = useCallback(async (emails) => {
    try {
      delete emails._id;
      delete emails.createdAt;
      delete emails.updatedAt;
      const emailsResponse = await axios.put('/emails', { emails });
      setEmails({ ...emailsResponse.data.emails });
      setSuccess({
        bg: 'success',
        message: 'Settings Updated.',
      });
      setTimeout(() => setSuccess(null), 6000);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getEmails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Email Customizer | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Email Customizer
        </Typography>
        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}
        <EmailForm emails={emails} updateEmails={updateEmails} />
      </Container>
    </>
  );
}
