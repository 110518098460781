import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Alert } from '@mui/material';
import Profile from '../sections/@dashboard/account/Profile';
import { useAuthContext } from '../auth/useAuthContext';
import axios from '../utils/Axios';

export default function ProfilePage() {
  const { user, setGlobalUser } = useAuthContext();
  const [success, setSuccess] = useState(null);
  const [formErrors, setFormErrors] = useState(null);

  const updateUser = useCallback(async (userData) => {
    try {
      const response = await axios.put(`/user`, userData);
      setGlobalUser(response.data.user);
      setSuccess({ bg: 'success', message: 'Settings Updated.' });
      setTimeout(() => setSuccess(null), 6000);
    } catch (error) {
      setFormErrors({ message: error.messages.join('\n') });
      setTimeout(() => setFormErrors(null), 6000);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Profile | {localStorage.getItem("companyName") ?? "FlowBookings"}</title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Profile
        </Typography>
        {!!formErrors && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {formErrors.message}
          </Alert>
        )}
        {success != null && (
          <Alert severity={success.bg} color="info" sx={{ mb: 2 }}>
            {success.message}
          </Alert>
        )}
        <Profile user={user} updateUser={updateUser} />
      </Container>
    </>
  );
}
